import React from 'react';
import { Newsletter, Jumbotron } from '../components';
import { Features } from '../components/PressComponents';
import Layout from '../containers/Layout'

export default function Press() {
    return (
        <Layout title={"Media"}>
            <Jumbotron
                btn="Media"
                title="We've been featured on some prominent media names in the industry"
                subTitle='Read some of our features'
            />
            <Features />
            <Newsletter />
        </Layout>
    );
}