import React from 'react';
import ArticleCard from './ArticleCard';
import { features } from '../../utils/constants'
import * as Animate from "react-reveal"

export default function Features() {
  return (
    <section className='bg-white pb-4 lg:pb-10'>
      <div className='w-11/12 mx-auto mb-16 flex flex-col  max-w-7xl'>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-rows-3 gap-8'>
            {
              features.map((feature) => {
                return (
                  <ArticleCard key={feature.id} feature={feature} />
                )
              })
            }
          </div>
      </div>
    </section>
  );
}
